import get from 'lodash/get';

import getMetricProduct from './getMetricProduct';
import getMetricProductNew from './getMetricProductNew';

export const sendViewPromotionEvent = ({ element }) => {
  global.window?.dataLayer?.push({
    event: 'view_promotion',
    ecommerce: {
      promoView: {
        promotions: [
          {
            id: element?.dataset?.bannerId,
            name: element?.dataset?.bannerName,
            variant: element?.dataset?.bannerHref,
            position: element?.dataset?.bannerIndex,
          },
        ],
      },
    },
  });
};

export const sendSelectPromotionEvent = ({ item }) => {
  global.window?.dataLayer?.push({
    event: 'select_promotion',
    ecommerce: {
      promoClick: {
        promotions: [
          {
            id: item.id,
            name: item.name,
            variant: item.href,
            position: `Главная. Верхний слайдер. ${item.index + 1}`,
          },
        ],
      },
    },
  });
};

export const sendBeginCheckoutEvent = ({ cart }) => {
  const additionalProps = {};

  if (cart?.coupon?.value) {
    additionalProps.coupon = cart?.coupon?.value;
  }

  window.dataLayer?.push({
    event: 'begin_checkout',
    ecommerce: {
      checkout: {
        ...additionalProps,
        actionField: { step: 1 },
        products: cart.items.map((product) => {
          const lightMetricProduct = getMetricProduct({
            product,
            offer: product?.sizes?.length ? {
              id: product.offerId || product.productId,
              price: product.price,
            } : null,
            isLiberty: get(product, 'product_catalog') === 'liberty',
            isForceOfferIdToId: true,
          });

          return {
            ...lightMetricProduct,
            quantity: get(product, 'quantity', 0),
          };
        }),
      },
    },
  });
};

export const sendCheckoutProgressEvent = ({ cart }) => {
  const additionalProps = {};

  if (cart?.coupon?.value) {
    additionalProps.coupon = cart?.coupon?.value;
  }

  global.window?.dataLayer?.push({
    event: 'checkout_progress',
    ecommerce: {
      checkout: {
        ...additionalProps,
        actionField: { step: 2 },
        products: cart.items.map((product) => {
          const lightMetricProduct = getMetricProduct({
            product,
            offer: product?.sizes?.length ? {
              id: product.offerId || product.productId,
              price: product.price,
            } : null,
            isLiberty: product.type === 'liberty',
            isForceOfferIdToId: true,
          });

          return {
            ...lightMetricProduct,
            quantity: get(product, 'quantity', 0),
          };
        }),
      },
    },
  });
};

export const sendAddShippingInfoEvent = ({ cart, title }) => {
  const additionalProps = {};

  if (cart?.coupon?.value) {
    additionalProps.coupon = cart?.coupon?.value;
  }

  global.window.dataLayer?.push({
    event: 'add_shipping_info',
    ecommerce: {
      checkout: {
        ...additionalProps,
        actionField: {
          step: 2,
          shipping_tier: title,
        },
        products: cart.items?.map((product) => {
          const lightMetricProduct = getMetricProduct({
            product,
            offer: product?.sizes?.length ? {
              id: product.offerId || product.productId,
              price: product.price,
            } : null,
            isLiberty: product.type === 'liberty',
            isForceOfferIdToId: true,
          });

          return {
            ...lightMetricProduct,
            quantity: get(product, 'quantity', 0),
          };
        }),
      },
    },
  });
};

export const sendAddPaymentInfoEvent = ({ cart, title }) => {
  const additionalProps = {};

  if (cart?.coupon?.value) {
    additionalProps.coupon = cart?.coupon?.value;
  }

  global.window.dataLayer?.push({
    event: 'add_payment_info',
    ecommerce: {
      checkout: {
        ...additionalProps,
        actionField: {
          step: 2,
          payment_type: title,
        },
        products: cart.items?.map((product) => {
          const lightMetricProduct = getMetricProduct({
            product,
            offer: product?.sizes?.length ? {
              id: product.offerId || product.productId,
              price: product.price,
            } : null,
            isLiberty: product.type === 'liberty',
            isForceOfferIdToId: true,
          });

          return {
            ...lightMetricProduct,
            quantity: get(product, 'quantity', 0),
          };
        }),
      },
    },
  });
};

export const sendMetricAddToBasket = ({ items }) => {
  if (items?.length) {
    const lightMetricProducts = items.map((item) => {
      const lightMetricProduct = getMetricProduct({
        product: item.product,
        offer: item.offer,
        categoryCode: item?.product?.section_code,
        isLiberty: item.isLiberty,
        isForceOfferIdToId: true,
      });

      return {
        ...lightMetricProduct,
        quantity: 1,
      };
    });

    global.window?.dataLayer?.push({
      event: 'add_to_cart',
      ecommerce: {
        add: {
          products: lightMetricProducts,
        },
      },
    });

    items.forEach((item) => {
      const metricProduct = getMetricProduct({
        product: item.product,
        offer: item.offer,
        isLiberty: item.isLiberty,
        categoryCode: item.product?.section_code,
      });

      global.window?.fsayer('event', 'item', 'addToCart', {
        item_id: metricProduct.variant || metricProduct.id,
      });
    });
  }
};

// TODO: Refactor it
export const sendMetricAddToBasketNew = ({ items }) => {
  if (items?.length) {
    const lightMetricProducts = items.map((item) => {
      const lightMetricProduct = getMetricProductNew({
        product: item.product,
        offer: item.offer,
        categoryCode: item?.product?.section_code,
        isLiberty: item.isLiberty,
        isForceOfferIdToId: true,
      });

      return {
        ...lightMetricProduct,
        quantity: 1,
      };
    });

    global.window?.dataLayer?.push({
      event: 'add_to_cart',
      ecommerce: {
        add: {
          products: lightMetricProducts,
        },
      },
    });

    items.forEach((item) => {
      const metricProduct = getMetricProduct({
        product: item.product,
        offer: item.offer,
        isLiberty: item.isLiberty,
        categoryCode: item.product?.section_code,
      });

      global.window?.fsayer('event', 'item', 'addToCart', {
        item_id: metricProduct.variant || metricProduct.id,
      });
    });
  }
};
