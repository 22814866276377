import get from 'lodash/get';

export default function getMetricProduct({
  product,
  offer,
  categoryCode,
  isLiberty,
  isForceOfferIdToId,
}) {
  let productCategory;

  if (isLiberty) {
    if (categoryCode === 'light') {
      productCategory = 'Свет';
    }
    if (categoryCode === 'accessories') {
      productCategory = 'Аксессуары';
    }
    if (categoryCode === 'all-furniture') {
      productCategory = 'Мебель';
    }
  } else if (offer) {
    if (offer.price < 10000) {
      productCategory = 'Ковры до 10 тыс руб';
    } else if (offer.price < 30000) {
      productCategory = 'Ковры 10-30 тыс руб';
    } else if (offer.price < 50000) {
      productCategory = 'Ковры 30-50 тыс руб';
    } else if (offer.price < 100000) {
      productCategory = 'Ковры 50-100 тыс руб';
    } else if (offer.price < 150000) {
      productCategory = 'Ковры 100-150 тыс руб';
    } else if (offer.price < 200000) {
      productCategory = 'Ковры 150-200 тыс руб';
    } else if (offer.price < 500000) {
      productCategory = 'Ковры 200-500 тыс руб';
    } else if (offer.price >= 500000) {
      productCategory = 'Ковры от 500 тыс руб';
    } else if (Number.isNaN(+offer.price)) {
      productCategory = 'Ковры Цена по запросу';
    }
  }

  if (isLiberty) {
    const chunk = {
      name: product.name,
      id: +product.id,
      description: product.name,
      category: productCategory,
      price: product.price ? product.price.price : null,
      brand: product.brand ? product.brand.name : null,
      variant: get(product, 'country.name', null),
    };

    return chunk;
  }

  const chunk = {
    name: `${product.title} ${product.name}`,
    id: +product.id,
    category: productCategory,
    price: get(offer, 'price'),
    brand: get(product, 'collection.name', null),
    variant: +get(offer, 'id'),
  };

  if (isForceOfferIdToId) {
    chunk.id = +chunk.variant;
  }

  return chunk;
}
